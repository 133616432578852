// extracted by mini-css-extract-plugin
export var degreesBanner = "styles-module--degreesBanner--htqJN";
export var image = "styles-module--image--vZQ42";
export var content = "styles-module--content--WOchO";
export var slice = "styles-module--slice--sV99f";
export var headerSlice = "styles-module--headerSlice--WA0p4";
export var headerReverseSlice = "styles-module--headerReverseSlice--oj9jj";
export var richText = "styles-module--richText--J2S3y";
export var play = "styles-module--play--FBp9Z";
export var myAxelosHeader = "styles-module--myAxelosHeader--KmDfc";
export var Aqua = "styles-module--Aqua--wFWwe";
export var RightAlignText = "styles-module--RightAlignText--81S9C";
export var ProductProPath = "styles-module--ProductProPath--zjHVX";
export var ProductItil = "styles-module--ProductItil--aDJLA";
export var ProductMaturity = "styles-module--ProductMaturity--nWAHy";
export var ProductTransformation = "styles-module--ProductTransformation--qIihU";
export var ProductResilia = "styles-module--ProductResilia--5Vake";
export var LeftAlignText = "styles-module--LeftAlignText--ezv-U";