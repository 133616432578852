import React from "react";
import clsx from "clsx";
import { useEqualHeights } from "../Card/hooks";

import Slider from "react-slick";
import { ProductCardsItemProps, ProductCardsProps } from "../types";
import * as Cta from "components/Cta";
import * as Product from "../index";
import { htmlSafe } from "modules/utils";

import { ButtonStyle } from "components/Button/constants";
import "slick-carousel/slick/slick.css";
import * as styles from "./styles.module.scss";
import * as wrapperStyles from "../wrapper.module.scss";

const Carousel: React.FunctionComponent<ProductCardsProps> = ({
  background_colour,
  cards_per_row,
  cta,
  products,
  slide_speed,
  text,
  title,
  lazyload,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: false,
    pauseOnHover: true,
    slidesToShow: cards_per_row ? cards_per_row : 3,
    slidesToScroll: cards_per_row ? cards_per_row : 3,
    autoplaySpeed: slide_speed ? slide_speed : 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const className = clsx(
    wrapperStyles.productsWrapper,
    background_colour && wrapperStyles[`${background_colour?.replace(/\s/g, "")}`],
    background_colour !== "White" && wrapperStyles.colouredBackground,
    lazyload && "componentLazyload"
  );
  const carouselClassName = clsx(
    styles.productsCarousel,
    cards_per_row === 1 && styles.One,
    cards_per_row === 4 && styles.Four,
    cards_per_row === 4 ? "container" : "container small"
  );
  const { addToRefs, height } = useEqualHeights();
  return (
    <div className={className}>
      {(title || text) && (
        <section>
          <div className="container small">
            <div>
              <h2>{title}</h2>
              {text && <p>{htmlSafe(text)}</p>}
            </div>
          </div>
        </section>
      )}
      <div>
        <div className={carouselClassName}>
          <Slider {...settings}>
            {products?.map((product: ProductCardsItemProps, key: any) => {
              return (
                <div className={styles.cardItem} key={key}>
                  <Product.Card body={product.body} forwardedRef={addToRefs} header={product.header} height={height} />
                </div>
              );
            })}
          </Slider>
          {(cta.page_link?.length !== 0 || cta.custom_url !== "") && (
            <div className={wrapperStyles.seeMoreCta}>
              <Cta.Button {...cta} defaultColour={ButtonStyle.BRAND_AQUA} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
