import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useEqualHeights } from "../Card/hooks";

import { ProductCardsItemProps, ProductCardsProps } from "../types";
import { ButtonStyle } from "components/Button/constants";
import * as Cta from "components/Cta";
import * as Product from "../index";
import { htmlSafe } from "modules/utils";

import "slick-carousel/slick/slick.css";
import * as styles from "./styles.module.scss";
import * as wrapperStyles from "../wrapper.module.scss";
import { ModalInfoTemplate } from "components/Modal/Info";
import ModalOverlay from "components/Modal/Overlay";

import Cookies from "universal-cookie";

const Grid: React.FunctionComponent<ProductCardsProps> = ({
  background_colour,
  cards_per_row,
  cta,
  products,
  text,
  title,
  lazyload,
}) => {
  const className = clsx(
    wrapperStyles.productsWrapper,
    background_colour && wrapperStyles[`${background_colour?.replace(/\s/g, "")}`],
    background_colour !== "White" && wrapperStyles.colouredBackground,
    lazyload && "componentLazyload"
  );
  const gridClassName = clsx(
    styles.productsGrid,
    cards_per_row === 1 && styles.One,
    cards_per_row === 2 && styles.Two,
    cards_per_row === 3 && styles.Three,
    cards_per_row === 4 && styles.Four,
    cards_per_row === 4 ? "container" : "container small"
  );
  const { addToRefs, height } = useEqualHeights();

  useEffect(() => {
    if (window.location.href.includes("for-professionals/my-axelos")) {
      const element = document.getElementById("goHere");
      if (element !== null) element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  });

  // const handleCloseModal = () => {
  //   setOpenModal(false);

  //   const element = document.getElementById("goHere");
  //   if (element !== null) element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
  // window.scroll({ top: element?.offsetHeight, behavior: "smooth" });
  //};

  return (
    <div id="goHere" className={className}>
      {(title || text) && (
        <section>
          <div className="container small">
            <div>
              <h2>{title}</h2>
              {text && <p>{htmlSafe(text)}</p>}
            </div>
          </div>
        </section>
      )}
      <div>
        <div className={gridClassName}>
          <div>
            {products?.map((product: ProductCardsItemProps, key: any) => {
              return (
                <div className={styles.cardItem} key={key}>
                  <Product.Card body={product.body} forwardedRef={addToRefs} header={product.header} height={height} />
                </div>
              );
            })}
          </div>
          {(cta.page_link?.length !== 0 || cta.custom_url !== "") && (
            <div className={wrapperStyles.seeMoreCta}>
              <Cta.Button {...cta} defaultColour={ButtonStyle.BRAND_AQUA} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Grid;
