import React, { useState } from "react";
import clsx from "clsx";
import { htmlSafe, hasStartAndEndTag, useWindow } from "modules/utils";

import * as Button from "components/Button";
import * as Cta from "components/Cta";
import { ButtonStyle } from "components/Button/constants";
import DegreesSVG from "modules/theme/artwork/degrees.svg";
import MyAxelosLogoSVG from "modules/theme/logos/myaxelos.svg";
import PlaySVG from "modules/theme/icons/general/play.svg";
import { CtaProps } from "components/Cta/types";
import LazyloadImage from "modules/lazyload/Image";

import * as styles from "./styles.module.scss";
import "react-modal-video/scss/modal-video.scss";

export type DegreesBannerMediumProps = {
  background_colour: string;
  copy: string;
  cta: CtaProps;
  image: {
    title: string;
    url: string;
  };
  image_alt: string;
  show_cta: boolean;
  show_myaxelos_header: boolean;
  text_alignment: string;
  video_id: string;
  lazyload?: boolean;
};

const Medium: React.FC<DegreesBannerMediumProps> = ({
  background_colour,
  copy,
  cta,
  image,
  image_alt,
  show_myaxelos_header,
  text_alignment,
  video_id,
  lazyload,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [ModalVideo, setModalVideo] = useState<any>(null);
  const loadVideoComponent = async () => {
    const loadResult = await import("react-modal-video");
    setModalVideo(() => loadResult.default);
  };

  const className = clsx(
    styles.degreesBanner,
    styles[`${text_alignment}AlignText`],
    styles[`${background_colour.replace(/\s/g, "")}`],
    show_myaxelos_header && styles.myAxelosHeader,
    lazyload && "componentLazyload"
  );
  const alt = image_alt ? image_alt : image.title;
  const desktop = {
    height: 540,
    width: 960,
    aspectRatio: "16:9",
  };
  const mobile = {
    height: 225,
    width: 360,
    aspectRatio: "16:10",
  };

  return (
    <>
      <div className={className}>
        {show_myaxelos_header && (
          <header>
            <div className="container small">
              <MyAxelosLogoSVG />
              <h2>The best practice community that's all about you</h2>
            </div>
          </header>
        )}
        <div>
          {show_myaxelos_header && (
            <div className={styles.image}>
              <LazyloadImage alt={alt} desktop={desktop} mobile={mobile} src={image?.url} tagName="img" />
              <DegreesSVG className={styles.slice} />
              <DegreesSVG className={styles.headerSlice} />
              {text_alignment === "Left" && (
                <>
                  <DegreesSVG className={styles.headerReverseSlice} />
                  <DegreesSVG className={styles.headerReverseSlice} />
                  <DegreesSVG className={styles.headerReverseSlice} />
                </>
              )}
              {video_id && (
                <span className={styles.play}>
                  <Button.Generic onClick={() => loadVideoComponent().then(() => setIsOpen(!isOpen))}>
                    <PlaySVG />
                  </Button.Generic>
                </span>
              )}
            </div>
          )}
          <div className="container small">
            <div className={styles.content}>
              <div className={styles.richText}>
                <div>{hasStartAndEndTag(copy) ? htmlSafe(copy) : <p>{htmlSafe(copy)}</p>}</div>
              </div>
              <Cta.Button {...cta} defaultColour={ButtonStyle.WHITE} />
              <DegreesSVG className={clsx(styles.slice)} />
              <DegreesSVG className={styles.headerSlice} />
              {text_alignment === "Left" && (
                <>
                  <DegreesSVG className={styles.headerReverseSlice} />
                  <DegreesSVG className={styles.headerReverseSlice} />
                  <DegreesSVG className={styles.headerReverseSlice} />
                </>
              )}
            </div>
          </div>
          {!show_myaxelos_header && (
            <div className={styles.image}>
              <LazyloadImage alt={alt} desktop={desktop} mobile={mobile} src={image?.url} tagName="img" />
              <DegreesSVG className={styles.slice} />
              {video_id && (
                <span className={styles.play}>
                  <Button.Generic onClick={() => loadVideoComponent().then(() => setIsOpen(!isOpen))}>
                    <PlaySVG />
                  </Button.Generic>
                </span>
              )}
            </div>
          )}
          {video_id && useWindow() && ModalVideo && (
            <ModalVideo channel="youtube" isOpen={isOpen} videoId={video_id} onClose={() => setIsOpen(false)} />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(Medium);
