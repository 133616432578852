import React, { useState } from "react";
import clsx from "clsx";

import { htmlSafe, hasStartAndEndTag, useWindow } from "modules/utils";

import ChevronSVG from "modules/theme/icons/chevrons/chevron-degrees-banner.svg";
import * as Button from "components/Button";
import PlaySVG from "modules/theme/icons/general/play.svg";
import LazyloadImage from "modules/lazyload/Image";

import * as styles from "./styles.module.scss";
import "react-modal-video/scss/modal-video.scss";

export type DegreesBannerLargeProps = {
  background_colour: string;
  copy: string;
  image: {
    title: string;
    url: string;
  };
  image_alt: string;
  subheading: string;
  text_alignment: string;
  video_id: string;
  lazyload?: boolean;
};

const Large: React.FC<DegreesBannerLargeProps> = ({
  background_colour,
  copy,
  image,
  image_alt,
  subheading,
  text_alignment,
  video_id,
  lazyload,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [ModalVideo, setModalVideo] = useState<any>(null);
  const loadVideoComponent = async () => {
    const loadResult = await import("react-modal-video");
    setModalVideo(() => loadResult.default);
  };

  const className = clsx(
    styles.degreesBanner,
    styles[`${background_colour?.replace(/\s/g, "")}`],
    styles[`${text_alignment}AlignText`],
    lazyload && "componentLazyload"
  );

  const alt = image_alt ? image_alt : image.title;

  return (
    <>
      <div className={className}>
        <div className="container small">
          <div className={styles.content}>
            <div>
              {subheading && (
                <div>
                  <h2>{subheading}</h2>
                  <ChevronSVG className={styles.chevron} />
                </div>
              )}
              <div>
                {!subheading && <ChevronSVG className={styles.chevron} />}
                <div>
                  <div>{hasStartAndEndTag(copy) ? htmlSafe(copy) : <p>{htmlSafe(copy)}</p>}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.image}>
          <LazyloadImage
            alt={alt}
            desktop={{ height: 360, width: 1920, aspectRatio: "16:3" }}
            mobile={{ height: 200, width: 360, aspectRatio: "9:5" }}
            src={image?.url}
            tagName="img"
          />
          <div className={styles.slice}></div>
          {video_id && (
            <span className={styles.play}>
              <Button.Generic onClick={() => loadVideoComponent().then(() => setIsOpen(!isOpen))}>
                <PlaySVG />
              </Button.Generic>
            </span>
          )}
        </div>
        {video_id && useWindow() && ModalVideo && (
          <ModalVideo channel="youtube" isOpen={isOpen} videoId={video_id} onClose={() => setIsOpen(false)} />
        )}
      </div>
    </>
  );
};

export default Large;
