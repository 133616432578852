// extracted by mini-css-extract-plugin
export var degreesBanner = "styles-module--degreesBanner--bRfDq";
export var image = "styles-module--image--HW310";
export var content = "styles-module--content--p10LV";
export var chevron = "styles-module--chevron--YIZEZ";
export var slice = "styles-module--slice--t8HSz";
export var play = "styles-module--play--o1LKI";
export var BlackGrape = "styles-module--BlackGrape--tPaA0";
export var Monochrome5 = "styles-module--Monochrome5--KY+nu";
export var Monochrome2 = "styles-module--Monochrome2--bQXEK";
export var LeftAlignText = "styles-module--LeftAlignText--DtcEc";
export var RightAlignText = "styles-module--RightAlignText--7kIpe";