// extracted by mini-css-extract-plugin
export var degreesBanner = "styles-module--degreesBanner--Kx9e6";
export var image = "styles-module--image--Bb-7j";
export var content = "styles-module--content--47+Dc";
export var chevron = "styles-module--chevron--B41oM";
export var slice = "styles-module--slice--UULJd";
export var play = "styles-module--play--WM7xM";
export var BlackGrape = "styles-module--BlackGrape--CdzQd";
export var Monochrome5 = "styles-module--Monochrome5--eQJXI";
export var Monochrome2 = "styles-module--Monochrome2--BFmql";
export var LeftAlignText = "styles-module--LeftAlignText--xxW3S";
export var RightAlignText = "styles-module--RightAlignText--oVFcW";