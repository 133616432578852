import React, { useEffect, useRef, useState } from "react";

const getMaxHeight = (refs: React.MutableRefObject<HTMLDivElement[]>) => {
  return Math.max.apply(
    Math,
    refs.current.map((i: HTMLDivElement) => i.clientHeight)
  );
};

export const useEqualHeights = () => {
  const refs = useRef<HTMLDivElement[]>([]);

  const addToRefs = (el: HTMLDivElement) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const [height, setHeight] = useState(getMaxHeight(refs));

  const handleResize = () => {
    setHeight(getMaxHeight(refs));
  };

  useEffect(() => {
    setHeight(getMaxHeight(refs));
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [handleResize, refs, height]);

  return { addToRefs, height };
};
